.PageTitle {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  color: #05364f;
  @media only screen and (max-width: 1575px) and (min-width: 600px)  {
    // margin-left: 75px;
  }
}

.PageSubTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 300;
  color: #707070;
  margin-bottom: 1.5em;
  @media only screen and (max-width: 1575px) and (min-width: 600px)  {
    // margin-left: 75px;
  }
}