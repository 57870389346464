@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?uqkdbo');
  src:  url('../fonts/icomoon.eot?uqkdbo#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?uqkdbo') format('truetype'),
  url('../fonts/icomoon.woff?uqkdbo') format('woff'),
  url('../fonts/icomoon.svg?uqkdbo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-btc:before {
  content: "\e904";
}
.icon-icon-tp:before {
  content: "\e900";
}
.icon-icon-tg:before {
  content: "\e901";
}
.icon-icon-tx:before {
  content: "\e902";
}
.icon-icon-tc:before {
  content: "\e903";
}
