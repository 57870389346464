// Configurable Colors

$background: #f1f3f8;

$primary-color: #09c199;
$primary-dark-color: #079a7a;
$secondary-color: #05364f;
$secondary-dark-color: #032537;
$accent-color: #008CFF;
$accent-dark-color: #008CFF;
$very-dark-blue: #05202D;
$yellow:#E9BF4A;

$color-moc-tc: #ef8a13;
$color-moc-tx: #ed1c24;
$color-moc-tp: #00a651;
$color-moc-reserve: #08374F;
$color-moc-tg: #68cdc6;

$color-roc-tc: #0061bb;
$color-roc-tp: #0083ff;
$color-roc-tx: #67b8ff;
$color-roc-reserve: #08374F;
$color-roc-tg: #68cdc6;

$color-moc: #68cdc6;

$color-pending: #d49400;

$theme-color-yellow: linear-gradient(90deg,rgba(247,107,28,.7),rgba(250,217,97,.7));

$theme-color-tc: linear-gradient(to right, #ffb415, #ef8a13);
$theme-color-tx: linear-gradient(to right, #f2316a, #ed1c24);
$theme-color-tp: linear-gradient(to right, #3fcb97, #00a651);

$theme-color-allowance: linear-gradient(to right, rgba(5, 54, 79, 0.7), rgba(64, 129, 201, 0.7));
$theme-color-total-balance: linear-gradient(to right, rgba(5, 54, 79, 1), rgba(9, 193, 153, 0.8));
$theme-color-incentives: linear-gradient(to right, rgba(0, 165, 152, 1), rgba(0, 213, 196, 0.7));
$theme-color-pending: linear-gradient(to right, rgba(140, 165, 0, 1), rgba(213, 114, 0, 0.7));
$theme-color-sent: linear-gradient(to right, rgba(0, 135, 182, 1), rgba(0, 183, 226, 0.7));

$red: #ed1c24;

// Configurable Font

@mixin primary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}

@mixin secondary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}
