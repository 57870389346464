.token-card-organizer {
    display: flex;
    flex-direction: column;
    height: 135px;
}

.TEST {
    border: 1px dashed red !important;
    background-color: rgba(255, 193, 193, 0.474) !important;
}
.sect__home {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    height: 435px;
}
.sect__home__wallet {
}
.sect__home__tokens {
    margin: 0px;
    // border: 4px dashed greenyellow !important;
    width: 100%;
}
.sect__home__liquidity {
}
.TokenSummaryCard {
    width: 295px;
}
.token__summary__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90px;
}
.token__summary__content__balance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 250px !important;
}
.token__summary__content__usdbtc {
    text-align: right;
}
.token__summary__content__button {
    display: flex;
    justify-content: flex-end;
    width: 50px;
}
.sect__home__liquidity {
    width: 253px !important;
}
.button__arrow--right {
    filter: invert(50%) sepia(98%) saturate(385%) hue-rotate(117deg)
        brightness(90%) contrast(96%);
    background-image: url('./../icons/button-arrow-right.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
}
.sect__exchange__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}
.sect__exchange__header {
    display: flex;
    flex-direction: column;
}
.sect__exchange__cards__container {
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.sect__exchange__balance {
    width: 280px;
}
.sect__exchange__address {
    width: 280px;
}
.sect__exchange__card {
}

.WalletBalance {
    width: 295px;
}

.gov__staking__options {
    width: 100%;
    display: flex;
    align-items: stretch;
}
.sect__staking {
    display: flex;
    flex-direction: row;
}
.sect__staking__stake {
    display: flex;
    flex-direction: column;
    max-width: 520px;
}
.sect__staking__performance {
    width: 156px;
    margin-right: 32px;
}
.sect__staking__overview__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}
@media all and (max-width: 600px) {
    .TokenSummaryCard {
        width: 100%;
    }
    .WalletBalance {
        width: 100%;
    }
    .token__summary__content__usdbtc {
        margin-top: 8px;
        margin-bottom: 16px;
    }
    .sect__home {
        flex-direction: column;
        gap: 16px;
        height: auto;
    }
    .TokenSummaryCard {
        // width: 100%;
    }
    .token__summary__content {
        flex-direction: column;
        height: auto;
    }
    .token__summary__content__balance {
        flex-direction: column;
    }
    .token__summary__content__usdbtc {
        text-align: center;
    }
    .sect__home__liquidity {
        width: 100% !important;
    }
    .sect__exchange__cards__container {
        flex-direction: column;
    }
    .sect__exchange__balance {
        width: 100%;
    }
    .sect__exchange__address {
        width: 100%;
    }
    .ant-btn,
    .ant-btn-default,
    .ant-btn-secondary {
        // width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .sect__staking__performance {
        display: none;
    }
    .ant-layout-header {
        display: flex;
        justify-content: space-between;
        .ant-select {
            font-size: 10px;
            width: 52px;
        }
    }
    .header-logo {
        width: 64px !important;
    }
    .ant-modal-body {
        // padding: 0;
    }
    .StakingOptionsModal_Content {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .ActionButtonsRow {
        gap: 16px;
    }
}
