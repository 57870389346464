@import 'general_styling';

.rlogin-modal-header {
    background-color: #08374f !important;
}

.rlogin-modal-body {
    border: 4px solid #08374f !important;
    border-bottom-right-radius: 11px !important;
    border-bottom-left-radius: 11px !important;
}

.ant-modal-header {
    background-color: #08374f !important;
    padding: 8px 24px !important;
    border-radius: 0 0 0 0 !important;
}

.ant-modal-close-x {
    font-size: 21px !important;
}

.ant-modal-content {
    border: 4px solid #08374f !important;
}

.ant-modal-title,
.ant-modal-close {
    color: white !important;
    text-align: center !important;
}

/* AddressContainer */

.AddressContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;

    hr {
        width: 100%;
        border: none;
        border-top: 1px solid $grey;
        margin: 1em 0;
    }

    .TitleAddress {
        @include primary-text(0.8em, $secondary-color, 400);
        text-transform: uppercase;
        margin-bottom: 1.5em;
    }

    img {
        margin: 0 auto;
    }
}

.RNSLink {
    font-size: 0.9em !important;
    color: $primary-color;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

/* AlertLabel */

.AlertLabel {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e;
    border-radius: 3px;
    width: 100%;
    margin-bottom: 2em;
    padding: 0.75em;
    display: flex;
    align-items: flex-start;

    img {
        margin-right: 0.75em;
        width: 24px;
    }

    .AlertText {
        p {
            //@include primary-text(1em, #f5222d, 400);

            strong {
                font-weight: 600;
            }
        }
    }
}

.AlertMaxAmount {
    width: 95%;
}

/* LoginButton */

.LoginButton {
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
    }
    .Text {
        font-size: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        line-height: 16px;
        .Title {
        }
        .Subtitle {
            color: #ef8a13;
            font-size: 12px;
        }
    }
    .StatusIcon {
        margin-left: 10px;
        width: 25px;
        height: 25px;
        border: solid 1px #878787;
        border-radius: 50%;
        content: ' ';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        .Circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #878787;
            margin: 0 auto;
        }
        &.Active {
            border-color: #fff;
            .Circle {
                background: #079a7a;
            }
        }
    }
}

.ant-drawer-header {
    padding: 25px 10px !important;
    .ant-drawer-header-title {
        justify-content: end;
    }
}
/*
.ant-select-selection {
  background-color: green;
}*/

//::ng-deep .ant-select-selector {
//  background-color: red;
//}

.ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    color: white !important;
}

.container_flag {
    color: white !important;
}

.ant-select-arrow {
    color: white !important;
}
.ant-select-item-option-content > .container_flag {
    padding-left: 5px;
    padding-right: 13px;
    text-align: center;
    color: #000 !important;
}

.customSelect {
    border-left: 1px solid white;
}

/* BalanceItem */

.BalanceItem {
    display: flex;
    flex-flow: row;
    box-sizing: border-box;
    align-items: baseline;

    h1 {
        margin: 0;
        font-family: Montserrat, sans-serif;
        font-size: 15px !important;
        font-weight: 500;
        color: #707070;
    }

    h4 {
        margin: 0;
        font-family: Montserrat, sans-serif;
        font-size: 15px !important;
        font-weight: 500;
        color: #707070;
        margin-left: 0.3em;
    }
}

/* AmountCard */

.WalletCurrencyPrice {
    .item {
        margin-bottom: 0.8em;
    }

    h4 {
    }

    @media (max-width: 500px) {
        h1 > div > span {
            font-size: 12px !important;
        }
        h4 {
            font-size: 12px !important;
        }
    }
}

/* BtcToRbtc */

.BtoRCard {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    height: 20em;
    padding: 0 !important;

    .m-b {
        padding-top: 25px;
        padding-bottom: 40px;
    }

    .title {
        background: #08374f;
        padding: 7px 12px !important;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        min-height: 48px;
        align-items: center;
        //padding:0;
        h1 {
            font-family: Quicksand, sans-serif;
            color: #fff !important;
            //font-family: Source Sans Pro,sans-serif;
            font-weight: 700 !important;
            line-height: 18px;
            font-size: 14px !important;
            margin-left: 6px;
        }
    }

    .text-center {
        text-align: center;
    }

    .logo-img {
        width: 59px;
        position: relative;
    }

    p {
        display: block;
        min-height: 8em;
    }
}

/* MocAmount */

.RewardsBalanceAmount {
    margin-bottom: 14px;
    height: 100%;
    position: relative;
    @media screen and (max-width: 900px) {
        margin-top: 15px;
    }
    .Tooltip {
        position: absolute;
        top: 25px;
        right: 25px;
        color: #707070;
        cursor: pointer;
    }
}

.ContainerMocAmountDatas {
    display: flex;
    flex-direction: column;
    height: 100%;
    .Card.withPadding .title {
        padding: 0;
        h1 {
            margin: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #707070;
        }
    }
    .TooltipMoC {
        /*position: absolute;
    top: 20px;
    right: 20px;*/

        position: relative;
        top: -30px;
        right: -225px;
        @media only screen and (max-width: 1575px) and (min-width: 600px) {
            top: -30px;
            right: -130px;
        }
        @media screen and (max-width: 900px) {
            top: -35px;
            right: -280px;
        }
        @media screen and (max-width: 400px) {
            top: -35px;
            right: -250px;
        }
    }
    .MocLogo {
        width: 45px;
    }
    .LogoAndAmount {
        display: flex;
        align-items: center;
        //margin-top: -10px;
        margin-top: 0.8rem;
        .TotalAmountContainer {
            margin-left: 8px;
        }
    }
    h2 {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        font-weight: 400;
        color: #8c8c8c;
    }
    .TotalAmount span {
        color: #68cdc6;
    }
}
.BalanceItemCard {
    box-sizing: border-box;
    align-items: baseline;
}

.BalanceItemCard h4 {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #032537;
}

@media screen and (max-width: 900px) {
    .ContainerMocAmount {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;
        margin-left: 0;
    }
}

.height-auto {
    height: auto;
}

.ant-col-xl-4 {
    //padding-right: 0 !important;
}

.sec-1 {
    width: 22%;
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width: 100px) and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) and (max-width: 1430px) {
        width: 30%;
    }
}

.sec-2 {
    width: 59%;
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width: 100px) and (max-width: 1023px) {
        width: 100%;
        margin-top: 10px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1430px) {
        width: 48%;
    }
}

.sec-3 {
    width: 19%;
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width: 100px) and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) and (max-width: 1430px) {
        width: 22%;
    }
}

/* MocLiquidity */

.RewardsBalanceLiquidity {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    position: relative;

    h1 {
        width: 80%;
    }

    h2 {
        font-size: 1em;
    }
    .IncentivesItem {
        display: flex;
        flex-flow: row;
        align-items: baseline;
        h3 {
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #68cdc6;
            margin: 0 0.15em 0 0;
        }
        p {
            margin: 0;
            font-family: Montserrat, sans-serif;
            font-size: 1em;
            font-weight: 400;
            color: #68cdc6;
        }
    }

    .claimButton {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        margin: 25px auto auto;
    }
    .Tooltip {
        position: absolute;
        top: 25px;
        right: 25px;
        color: #707070;
        cursor: pointer;
    }
    .Separator {
        border-bottom: 1px solid #e1e1e1;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.Card.withPadding {
    padding: 1.6em;
    height: 135px;
    //width: 248px;
    //@media (max-width: 600px) {
    //  width: 248px;
    //}
}

.Card {
    .withPadding {
        padding: 1.6em;
    }
    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1.6em 1.6em 0;
        margin-bottom: 15px;
        h1 {
            margin: 0;
            font-family: Montserrat, sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #707070;
        }
    }
}

.ContainerMocAmount {
    .Metric {
        margin-top: 10px;
    }
}

.test {
    flex: auto;
}

/* RewardsStakingOptionsCard */

.RewardsStakingOptions {
    min-height: 32.4em;
}

.WithdrawalAmount {
    display: inline;
}
.StakingTabContent {
    color: #8c8c8c;

    .RewardsOptionsOverview {
        justify-content: space-between;
        font-family: 'Montserrat', sans-serif;
        .amount {
            color: #000000;
            margin: 0;
            font-weight: 400;
            display: flex;
            flex-flow: row;
            align-items: baseline;
            font-size: 14px;
            div {
                margin-right: 0.15em;
            }
        }
    }

    .StakingBtn {
        margin: 1.5rem auto auto;
    }
}

.WithdrawTabFooter {
    min-height: calc(242px + -9rem);
    .amount {
        display: inline;
    }
    .bolder {
        font-weight: 600;
    }

    .grey {
        color: #707070;
    }
}

.ant-table-tbody > tr > td {
    border-bottom: none !important;
}

.ant-tabs-content {
    // height: 26.4em;
}

.typography {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
}

.RewardsOptionsContainer .ant-table-content {
    height: calc(180px + 1rem);
    overflow: auto;
}

/* Sovryn */

.FastbtcContainer,
.FastBTCards,
.FastBTCCard,
.FastCard {
    height: 20em;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    .title {
        background: #000;
        padding: 7px 12px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        min-height: 48px;
        align-items: center;
        width: 100%;
        //padding:0;
        h1 {
            font-family: Quicksand, sans-serif;
            color: #fff;
            //font-family: Source Sans Pro,sans-serif;
            font-weight: 700;
            line-height: 18px;
            font-size: 14px;
            margin-left: 6px;
        }
    }

    .CardLogo {
        display: flex;
        flex-direction: row;
    }

    .content-container {
        padding: 13px;
        ul {
            // margin-top: 15px;
            padding-left: 15px;
            li {
                line-height: 19px;
            }
        }
        a {
            color: #4ec199;
        }
    }

    .logo-img {
        position: absolute;
        opacity: 0.1;
    }
    .FastBTCLeftPanel {
        margin-top: 54px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        //margin: auto;
        text-align: left;
    }
}

.FastBTCLeftPanel {
    @media only screen and (max-width: 1432px) and (min-width: 1200px) {
        margin-top: 30px !important;
    }
}

/* WalletBalance */

.WalletBalance {
    padding: 1.6em;
    border-radius: 6px;
    /*box-shadow: 0 1px 6px rgb(147, 141, 158 / 16%), 0 2px 6px rgb(52, 53, 62 / 16%);*/
    box-shadow: 0 1px 6px rgba(189, 188, 189, 0.34),
        0 2px 6px rgba(189, 188, 189, 0.34);
    background: #fff;
    border-radius: 6px;
    flex-grow: 0;
    @media screen and (max-width: 900px) {
        text-align: -webkit-center;
    }

    @media screen and (max-width: 1024px) {
        padding-right: 0;
    }

    .CardTitle {
        font-family: Montserrat, sans-serif;
        font-size: 15px;
        font-weight: 400;
    }

    .money-RBTC {
        position: relative;
        top: -145px;
        display: block;
        text-align: center;
        margin: 0;
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: 600;
        color: #575756;
        width: 90%;
        margin-left: 10px;
    }
    .money-USD {
        position: relative;
        top: -145px;
        display: block;
        text-align: center;
        margin: 0;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #878787;
        width: 90%;
        margin-left: 10px;
    }

    .TotalBalanceBottom {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        @media screen and (max-width: 900px) {
            flex-direction: column;
            gap: 16px;
        }

        .CopyableText .title {
            color: #09c199;
            font-weight: 700;
            font-size: 0.85em;
        }

        button {
            width: 105px;
        }

        .CopyableText {
            text-align: center;
        }

        .CopyableText div .CopyIcon {
            margin-right: 10px;
            width: 17px;
            height: 17px;
            cursor: pointer;
        }

        .CopyableText .title {
            color: #09c199;
            font-weight: 700;
        }
    }
}

.ButtonPrimary {
    background: #09c199;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    padding: 0px 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    -webkit-transition: 500ms ease-out;
    -moz-transition: 500ms ease-out;
    -o-transition: 500ms ease-out;
    box-shadow: 0 2px 4px rgba(0, 195, 156, 0.2),
        0 2px 4px rgba(0, 195, 156, 0.2);
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.ButtonPrimary:hover {
    background-color: #05202d;
}

.mrc-15 {
    margin-right: 0px;
    @media screen and (min-width: 450px) {
        //margin-right: 15px;
    }
}
.WalletCardsContainerPie {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 900px) {
        flex-direction: row;
    }
    width: 100%;
    row-gap: 15px;

    @media only screen and (max-width: 1575px) and (min-width: 600px) {
        padding-left: 75px;
        // margin-left: 75px;
    }
}

//.TotalBalanceCard .TotalBalanceTop .pieChartTooltip {
.pieChartTooltip {
    z-index: 9;
    background: #05364f;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px !important;
    font-weight: 600;
    color: white;
    .value-1 {
        margin-bottom: 0;
    }
    .TP {
        color: #00a651;
        margin-bottom: 0;
    }
    .TC {
        color: #ef8a13;
        margin-bottom: 0;
    }
    .TX {
        color: #00c49f;
        margin-bottom: 0;
    }
    .RBTC_MAIN {
        color: #808080;
        margin-bottom: 0;
    }
}

.recharts-tooltip-wrapper {
    z-index: 2;
}

.WalletBalance-mb {
    width: 400px;
    @media screen and (max-width: 860px) {
        flex: 1 !important;
        width: 100%;
        margin-bottom: 15px;
    }
    //@media screen and (max-width:768px) {
    //  margin-bottom: 15px;
    //}
}

.padding-1-2 {
    padding: 1.2em !important;
}

.PieChart {
    .piePiece {
        &.TP {
            fill: $color-moc-tp;
        }
        &.TC {
            fill: $color-moc-tc;
        }
        &.TX {
            fill: $color-moc-tx;
        }
        &.MOC {
            fill: $color-moc;
        }
        &.EMPTY {
            fill: $low-grey;
        }
    }
    .chart-inner-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        padding-top: 40%;
        text-align: center;
    }

    .pieChartTooltip {
        z-index: 2;
        background: $secondary-color;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        @include primary-text(1em, white, 400);
        .TP {
            color: $color-moc-tp;
            font-weight: 700;
        }
        .TC {
            color: $color-moc-tc;
            font-weight: 700;
        }
        .TC & {
            color: $color-roc-tc;
            font-weight: 700;
        }
        .TX {
            color: $color-moc-tx;
            font-weight: 700;
        }
        .MOC {
            color: $color-moc;
            font-weight: 700;
        }
    }
}

/* InputWithCurrencySelector */

.text-align-right {
    text-align: right;
    span {
        margin-right: 0.1em !important;
    }
}
.InputWithCurrencySelector {
    h3 {
        @include primary-text(1em, $blue, 400);
    }
    .MainContainer {
        display: flex;
        align-items: center;
        width: 100%;
        .valueInput {
            width: inherit;
            border: none;
            border-bottom: 1px solid #e1e1e1;
            height: 48px;
            padding: 8px;
            background: #f7f7f7;
            @include primary-text(20px, $black, 400);
            &:hover,
            &:focus {
                @include input-style($primary-color);
            }
            &.formError {
                border-bottom-color: #ff4d4f;
            }
        }
        .SelectCurrency {
            width: 45%;
            max-width: 120px;
            .ant-select-selector {
                background-color: #f7f7f7;
                border: none;
                border-bottom: 1px solid #d9d9d9;
            }
            @media (max-width: 500px) {
                width: 50%;
            }
        }
    }
    .setValueToMaxLink {
        cursor: pointer;
        color: $primary-color;
    }

    .AlignedAndCentered {
        display: flex;
        justify-content: space-between;
    }
}

/* InputAddress */

.InputAddressContainer {
    .InputAddressLabel {
        @include primary-text(1em, $blue, 400);
        margin-bottom: 5px;
    }
    width: 100%;
    .input,
    input {
        // @include secondary-text(1em, $grey, 400);
        width: 100% !important;
        margin: 0 !important;
        border: none !important;
        border-bottom: 1px solid #e1e1e1 !important;
        height: 48px !important;
        padding: 8px !important;
        background: #f7f7f7 !important;

        &:hover,
        &:focus {
            @include input-style($primary-color);
        }

        &.formError {
            @include input-style(#ff4d4f);
        }
    }
    img {
        width: 25px;
    }
}

/* MintOrRedeemToken */

.MintOrRedeemToken {
    @media screen and (min-width: 100px) and (max-width: 1199px) {
        margin-top: 10px;
    }

    .ant-card-head {
        border-bottom: none !important;
        margin-bottom: -9px !important;
        font-family: Montserrat, sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: #707070;
    }
    .ant-card-body {
        padding-top: 0 !important;
    }
    padding: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    & > .title {
        margin-bottom: 20px;
    }
    .YouExchange,
    .YouReceive {
        width: 45%;
    }
    .ClearButton {
        margin-right: 15px;
    }
    .ReserveInUSD {
        .Disclaimer {
            color: $low-grey;
            max-width: 200px;
            line-height: 16px;
            font-size: 0.9em;
            display: block;
        }
        .Conversion {
            display: flex;
            .ReservePrice {
                margin-left: 5px;
            }
        }
    }
    .MintOrRedeemTokenFooter {
        margin-top: 30px;
    }
    .CommissionCurrencySwitch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        @include secondary-text(1em, black, 400);
        p {
            @include secondary-text(1em, black, 400);
        }
        margin-top: 1em;
    }
    .ExchangeInputs {
        svg {
            cursor: default;
        }
    }
    @media (max-width: 500px) {
        padding: 20px;
        .ExchangeInputs {
            flex-direction: column;
            .anticon-arrow-right {
                display: none;
            }
        }
        h1 {
            @include primary-text(1.3em, black, 400);
            margin-bottom: 20px;
        }
        .YouExchange,
        .YouReceive {
            width: 100%;
        }
        .YouReceive {
            margin-top: 20px;
        }
        .CommissionCurrencySwitch {
            width: 100%;
        }
        .MintOrRedeemTokenFooter {
            flex-direction: column;
            align-items: flex-start;
            .MainActions {
                flex-direction: column;
                width: 100%;
                button {
                    width: 90%;
                    margin: 15px 0 0 0;
                }
            }
        }
    }
}

.ReserveAllowanceModal {
    h1.ReserveAllowanceModal_Title {
        @include primary-text(1em, $grey, 400);
        position: absolute;
        top: 15px;
    }

    .ReserveAllowanceModal_Content {
        margin: auto;
        max-width: 400px;
        &.AllowanceLoading {
            text-align: center;
        }
        .ant-spin {
            // position: relative;
            // left: 50%;
            // transform: translateX(-50%);
            span[role='img'] {
                font-size: 72px;
                color: $primary-color;
                margin: 2rem 0;
            }
        }
    }

    .InfoContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        .title {
            @include primary-text(1em, black, 400);
        }
        .value {
            &.amount {
                @include primary-text(1em, black, 400);
                color: $primary-color;
                display: flex;
                span {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .ButtonPrimary {
        width: 130px;
        margin: auto;
        margin-top: 1rem;
    }

    .ActionButtonsRow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        .ButtonPrimary {
            margin: 1rem 0.25rem;
            margin-left: 0.5rem;
        }
    }
}

/* Hide arrows from input number, Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrows from input number, Works for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
